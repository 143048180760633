// IMPORT DEPENDENCIES
import "../../node_modules/picturefill/dist/picturefill.min.js";
import Swiper from 'swiper';
import jquery from 'jquery';

// VARS
window.$ = window.jQuery = jquery;
let jumps = [];

// IMPORT MODULES
import initMap from './modules/map';

// visible in viewport function
function isInViewport(idArr) {

    for(let i=0; idArr.length > i; i++) {
        if($(idArr[i]).length) {
            let elementTop = $(idArr[i]).offset().top,
                elementBottom = elementTop + $(idArr[i]).outerHeight(),
                viewportTop = $(window).scrollTop() + 100,
                viewportBottom = viewportTop + $(window).height();

            if(elementBottom > viewportTop && elementTop < viewportBottom) {
                $('.js-jumpnav').find('a').removeClass('is-active').eq(i).addClass('is-active');
                break;
            }
        }
    }
}



// init google map
if($('#map').length) {
    google.maps.event.addDomListener(window, 'load', initMap);
}

// disable autoscroll
if (location.hash) {
    setTimeout(function() {

        window.scrollTo(0, 0);
    }, 1);
}

// JQUERY DOCUMENT READY
$(document).ready(function () {

    // picturefill init
    picturefill();

    // sticky handling
    if($(window).scrollTop() > 0){
        $('header').addClass('is-sticky');
    }

    $(window).on('scroll', function(e) {
        e.preventDefault();

        if($(window).scrollTop() > 0){
            $('header').addClass('is-sticky');
        }else{
            $('header').removeClass('is-sticky');
        }

        // activate jump navigation links
        if($('.js-jumpnav').length){
            isInViewport(jumps);
        }
    });

    // menu layer
    $('.js-nav, .overlay').on('click', function(e) {
        e.preventDefault();
        let $nav = $('.js-nav');

        if($nav.hasClass('is-active')) {
            $nav.addClass('is-inactive').removeClass('is-active');
            $('body').removeClass('nav-open');
        }else{
            $nav.addClass('is-active').removeClass('is-inactive');
            $('body').addClass('nav-open');
        }
    });

    // jumplinks
    $('.js-jumper').on('click', function(e) {
        let href = $(this).attr('href').replace('/', ''),
            offset = $(href).offset().top - 150;

        $('html, body').animate({
            scrollTop: offset
        }, 1000);

        $('.js-nav').addClass('is-inactive').removeClass('is-active');
        $('body').removeClass('nav-open');

        e.preventDefault();
    });

    // init jump navigation
    if($('.js-jumpnav').length){

        $('.js-jumpnav').find('a').each(function() {
            jumps.push($(this).attr('href').replace('/', ''));
        });

        isInViewport(jumps);
    }


    // handle active class on active subpage
    $('.navigation li ul li.is-active').parents('.is-ancestor').addClass('is-active');

    // init swiper
    if($('.swiper-wrapper').children('li').length > 1) {
        let swiper = new Swiper ('.swiper-container', {
            slidesPerView: 1,
            loop: true,
            autoplay: {
                delay: 8000,
            },
            speed: 500,
            watchOverflow: true,
            pagination: false,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
        });
    }
});